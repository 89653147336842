import React from "react";

import SelectField from "material-ui/SelectField";
import TextField from "material-ui/TextField";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";
import CircularProgress from "material-ui/CircularProgress";
import FullscreenDialog from "material-ui-fullscreen-dialog";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";

import AppBar from "material-ui/AppBar";
import BackButton from "material-ui/svg-icons/navigation/arrow-back";
import IconButton from "material-ui/IconButton";
import Toggle from "material-ui/Toggle";

import moment from "moment";
import fetchClient from "../../components/utils/fetchClient";

import { DragDrop, StatusBar } from "@uppy/react";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import "@uppy/core/dist/style.css";
import "@uppy/status-bar/dist/style.css";

import withAuth from "../../components/auth/withAuth";

export class TaskAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            agentsList: [],
            propertiesList: [],
            hasAction: true,
            currentAction: "Loading...",
            task: {
                taskAgents: [],
                taskImages: [],
                imageIds: [],
                taskDate: moment().format("YYYY-MM-DD"),
                isExact: 0,
                taskType: 0
            },
            currentImage: "",
            modal_open: false,
            propertyModalOpen: false,
            propertyTasks: [],
            propertyTasksRecent: [],
            arrival: [],
            departure: []
        };

        this.handleGoBack = this.handleGoBack.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAgentSelectAgentChange = this.handleAgentSelectAgentChange.bind(this);
        this.handleSelectPropertyChange = this.handleSelectPropertyChange.bind(this);
        this.handleSelectTypeChange = this.handleSelectTypeChange.bind(this);
        this.addTask = this.addTask.bind(this);
        this.onToggleIsExact = this.onToggleIsExact.bind(this);
        this.handleClosePropertyDialog = this.handleClosePropertyDialog.bind(this);
        this.setDate = this.setDate.bind(this);

        var ciViewConfig = JSON.parse(localStorage.getItem("ciViewConfig"));
        if (ciViewConfig) {
            this.state.task.taskDate = ciViewConfig.view_date;
        }
    }

    addTask() {
        var self = this;

        fetchClient
            .post("ci/task", this.state.task)
            .then(function (response) {
                self.props.history.push("/");
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleGoBack() {
        this.props.history.push("/");
    }

    handleChange = event => {
        this.setState({ task: { ...this.state.task, [event.target.name]: event.target.value } });
    };

    handleAgentSelectAgentChange = (event, index, values) => {
        this.setState({ task: { ...this.state.task, taskAgents: values } });
    };

    handleSelectPropertyChange = async (event, index, values) => {
        this.setState({ task: { ...this.state.task, taskProperty: values } });

        const result = await this.getPropertyTasks(values);
        this.setState({ propertyTasks: result.tasks, arrival: result.arrival, departure: result.departure, propertyTasksRecent: result.recentTasks, propertyModalOpen: true });
    };

    async getPropertyTasks(value) {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get(`ci/tasks/${value}`)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    setDate(value) {
        this.setState({ task: { ...this.state.task, taskDate: moment(value).format("YYYY-MM-DD") } });
    }

    handleSelectTypeChange = (event, index, values) => {
        this.setState({ task: { ...this.state.task, taskType: values } });
    };

    handleDate = event => {
        this.setState({ task: { ...this.state.task, [event.target.name]: event.target.value } });
    };

    onToggleIsExact(event, isInputChecked) {
        var isToggled = isInputChecked ? 1 : 0;
        this.setState({ task: { ...this.state.task, isExact: isToggled } });
    }

    showModal(image) {
        if (image) {
            this.setState({ currentImage: image, modal_open: true });
        }
    }

    componentWillMount() {
        this.uploader = new Uppy({ id: "taskimage", autoProceed: true })
            .use(AwsS3, {
                getUploadParameters(file) {
                    return fetchClient
                        .post(
                            "upload",
                            JSON.stringify({
                                destination: "tasks",
                                upload_type: "task",
                                filename: file.name,
                                contentType: file.type
                            })
                        )
                        .then(response => {
                            return response.data;
                        })
                        .then(data => {
                            return {
                                method: data.method,
                                url: data.url,
                                fields: data.fields,
                                resultId: data.resultId
                            };
                        });
                }
            })
            .on("upload-success", (file, resp, uploadURL) => {
                var self = this;
                fetchClient
                    .post("store", { type: "task", url: uploadURL })
                    .then(function (response) {
                        self.setState({ task: { ...self.state.task, taskImages: [...self.state.task.taskImages, uploadURL], imageIds: [...self.state.task.imageIds, response.data.data.id] } });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            });
    }

    async componentDidMount() {
        const properties = await this.getProperties();
        const agents = await this.getAgents();

        this.setState({
            propertiesList: properties,
            agentsList: agents,
            hasAction: false,
            isLoading: false
        });
    }

    async getProperties() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("properties")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    async getAgents() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("agents")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    componentWillUnmount() {
        this.uploader.close();
    }

    menuItems(values) {
        return this.state.agentsList.map(agent => (
            <MenuItem key={agent.userName} insetChildren={true} checked={values && values.indexOf(agent.id) > -1} value={agent.agentId} primaryText={agent.userName} />
        ));
    }

    hourOptions = () => {
        let hours = [];
        for (let i = 0; i <= 23; i++) {
            let val = ("0" + i).slice(-2);
            hours.push(
                <option key={val} value={val}>
                    {val}
                </option>
            );
        }

        return hours;
    };

    minuteOptions = () => {
        let minutes = [];
        for (let i = 0; i <= 55; i += 5) {
            let val = ("0" + i).slice(-2);
            minutes.push(
                <option key={val} value={val}>
                    {val}
                </option>
            );
        }

        return minutes;
    };

    handleClosePropertyDialog() {
        this.setState({ propertyTasks: [], propertyModalOpen: false });
    }

    render() {
        const { taskAgents } = this.state.task;
        const { uploader } = this;

        const actions = [<FlatButton label="Ok" primary={true} onClick={this.handleClosePropertyDialog} />];

        return (
            <div>
                <AppBar
                    title={this.state.hasAction ? this.state.currentAction : "Add task"}
                    iconElementLeft={
                        <IconButton>
                            <BackButton onClick={this.handleGoBack} />
                        </IconButton>
                    }
                />

                {!this.state.isLoading ? (
                    <div style={{ paddingLeft: 15 }}>
                        <TextField name="taskDate" floatingLabelFixed={true} floatingLabelText="Task Date" onChange={this.handleDate} value={this.state.task.taskDate} type="date" />
                        <select name="taskHour" onChange={this.handleDate} value={this.state.task.taskHour}>
                            {this.hourOptions()}
                        </select>
                        :
                        <select name="taskMinute" onChange={this.handleDate} value={this.state.task.taskMinute}>
                            {this.minuteOptions()}
                        </select>
                        <br />
                        <br />
                        <Toggle name="isExact" toggled={this.state.task.isExact === 1 ? true : false} label={"Is Exact"} onToggle={this.onToggleIsExact} labelPosition="right" />
                        <SelectField
                            multiple={true}
                            name="taskAgents"
                            floatingLabelFixed={true}
                            selectedMenuItemStyle={{ fontWeight: "bold" }}
                            hintText="Task agents"
                            floatingLabelText="Agents"
                            value={this.state.task.taskAgents}
                            onChange={this.handleAgentSelectAgentChange}
                        >
                            {this.menuItems(taskAgents)}
                        </SelectField>
                        <br />
                        <SelectField
                            name="taskProperty"
                            floatingLabelText="Property"
                            floatingLabelFixed={true}
                            selectedMenuItemStyle={{ fontWeight: "bold" }}
                            hintText="Task property"
                            value={this.state.task.taskProperty}
                            onChange={this.handleSelectPropertyChange}
                        >
                            {this.state.propertiesList.map(property => {
                                return <MenuItem key={property.propertyId} value={property.propertyId} primaryText={property.propertyName} />;
                            })}
                        </SelectField>
                        <br />
                        <SelectField name="taskType" floatingLabelText="Type" floatingLabelFixed={true} hintText="Task type" value={this.state.task.taskType} onChange={this.handleSelectTypeChange}>
                            <MenuItem key={0} value={0} primaryText="Normal" />
                            <MenuItem key={1} value={1} primaryText="Refund" />
                            <MenuItem key={2} value={2} primaryText="Inventory" />
                            <MenuItem key={3} value={3} primaryText="Delivery" />
                            <MenuItem key={4} value={4} primaryText="Visit" />
                            <MenuItem key={5} value={5} primaryText="QC" />
                            <MenuItem key={6} value={6} primaryText="Stuart" />
                        </SelectField>
                        <br />
                        <TextField
                            floatingLabelText="Task Description"
                            floatingLabelFixed={true}
                            hintText="Description of task"
                            value={this.state.task.taskDesc}
                            multiLine={true}
                            name="taskDesc"
                            onChange={this.handleChange}
                            rows={1}
                        />
                        <br />
                        <TextField
                            floatingLabelText="Task Notes"
                            floatingLabelFixed={true}
                            hintText="Extra notes for task"
                            value={this.state.task.taskNotes}
                            multiLine={true}
                            name="taskNotes"
                            onChange={this.handleChange}
                            rows={1}
                        />
                        <div style={{ paddingTop: 10 }}>
                            <DragDrop
                                locale={{
                                    strings: {
                                        dropHereOr: "Upload image or %{browse}",
                                        browse: "browse"
                                    }
                                }}
                                uppy={uploader}
                            />
                            <StatusBar uppy={uploader} hideUploadButton hideAfterFinish={false} showProgressDetails />
                        </div>
                        {this.state.task.taskImages ? (
                            <div>
                                <br />
                                {this.state.task.taskImages.map((image, index) => (
                                    <RaisedButton
                                        style={{ marginBottom: 10, marginRight: 10 }}
                                        onClick={() => this.showModal(image)}
                                        key={parseFloat(index + 1)}
                                        label={"View Image #" + parseFloat(index + 1) + ""}
                                    />
                                ))}{" "}
                            </div>
                        ) : null}
                        <FullscreenDialog
                            open={this.state.modal_open}
                            onRequestClose={(...args) => {
                                this.setState({ modal_open: false });
                            }}
                            title="View Image"
                        >
                            <div style={{ paddingLeft: 15, paddingTop: 15, paddingRight: 15 }}>
                                <img style={{ width: "100%", height: "auto" }} src={this.state.currentImage} alt="View Task" />
                            </div>
                        </FullscreenDialog>
                        <RaisedButton primary={true} onClick={this.addTask} label="Add" />
                        <Dialog title="Existing tasks / info" actions={actions} modal={true} open={this.state.propertyModalOpen} autoScrollBodyContent={true}>
                            <h3>Next Checkout</h3>

                            {this.state.departure && (
                                <>
                                    <b onClick={() => this.setDate(this.state.departure.date)}>{moment(this.state.departure.date).format("DD/MM")}</b> - {this.state.departure.customer}
                                </>
                            )}
                            <h3>Next Checkin</h3>
                            {this.state.arrival && (
                                <>
                                    <b onClick={() => this.setDate(this.state.arrival.date)}>{moment(this.state.arrival.date).format("DD/MM")}</b> - {this.state.arrival.customer}
                                </>
                            )}
                            <h3>Future Tasks</h3>
                            {this.state.propertyTasks.map(task => {
                                return (
                                    <p style={task.Task_Done === "1" ? { color: "lightgrey", textDecoration: "line-through" } : null}>
                                        <b onClick={() => this.setDate(task.Task_Date)}>{moment(task.Task_Date).format("DD/MM")}</b> - {task.Task_Desc}
                                    </p>
                                );
                            })}

                            <h3>Recent Tasks</h3>
                            {this.state.propertyTasksRecent.map(task => {
                                return (
                                    <p style={task.Task_Done === "1" ? { color: "lightgrey", textDecoration: "line-through" } : null}>
                                        <b onClick={() => this.setDate(task.Task_Date)}>{moment(task.Task_Date).format("DD/MM")}</b> - {task.Task_Desc}
                                    </p>
                                );
                            })}
                        </Dialog>
                    </div>
                ) : (
                    <div style={{ paddingLeft: 10 }}>
                        <br />
                        <CircularProgress />
                    </div>
                )}
            </div>
        );
    }
}

export default withAuth(TaskAdd);
